<template>
    <b-col xl="4" class="d-flex p-2 flex-column" style="gap: 1.5rem">
        <div class="d-flex flex-column" style="gap: 1rem">
            <b-card-title>
                {{ $t(locale.changePassword) }}
            </b-card-title>
            <ValidationObserver ref="form">
                <b-row>
                    <b-col xl="12">
                        <b-row>
                            <b-col cols="12">
                                <ValidationProvider #default="{errors}" rules="required" :name="$t(locale.currentPassword)">
                                    <b-form-group>
                                        <div class="d-flex justify-content-between">
                                            <label v-text="$t(locale.currentPassword)" style="font-size: 14px" />
                                        </div>
                                        <b-input-group class="input-group-merge">
                                            <b-form-input v-model="password.current_password" :placeholder="$t(locale.currentPassword)" :type="currentPasswordHidden ?'password' : 'text'"/>

                                            <b-input-group-append is-text>
                                                <feather-icon class="cursor-pointer" :icon="currentPasswordHidden ? 'EyeIcon': 'EyeOffIcon'" @click="currentPasswordHidden = !currentPasswordHidden" />
                                            </b-input-group-append>
                                        </b-input-group>
                                        <small class="text-danger" v-text="errors[0]" />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>

                            <b-col cols="12">
                                <ValidationProvider
                                    #default="{errors}"
                                    rules="required|password"
                                    vid="password"
                                    :name="$t(locale.newPassword)"
                                >
                                    <b-form-group>
                                        <div class="d-flex justify-content-between">
                                            <label v-text="$t(locale.newPassword)" style="font-size: 14px" />
                                        </div>

                                        <b-input-group class="input-group-merge">
                                            <b-form-input
                                                v-model="password.new_password1"
                                                :type="newPasswordHidden ? 'password' : 'text'"
                                                :placeholder="$t(locale.newPassword)"
                                            />
                                            <b-input-group-append is-text>
                                                <feather-icon 
                                                    class="cursor-pointer"
                                                    :icon="newPasswordHidden ? 'EyeIcon' : 'EyeOffIcon'"
                                                    @click="newPasswordHidden = !newPasswordHidden"
                                                />
                                            </b-input-group-append>
                                        </b-input-group>

                                        
                                        <small class="text-danger" v-text="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>

                            <b-col cols="12">
                                <b-form-group>
                                    <div class="d-flex justify-content-between">
                                        <label v-text="$t(locale.confirmPassword)" style="font-size: 14px" />
                                    </div>
                                    <ValidationProvider
                                        #default="{errors}"
                                        rules="required|confirmed:password"
                                        :name="$t(locale.confirmPassword)"
                                    >
                                        <b-input-group class="input-group-merge">
                                            <b-form-input
                                                v-model="password.new_password2"
                                                :type="confirmPasswordHidden ?'password' : 'text'"
                                                :placeholder="$t(locale.confirmPassword)"
                                            />

                                            <b-input-group-append is-text>
                                                <feather-icon
                                                    class="cursor-pointer"
                                                    :icon="confirmPasswordHidden? 'EyeIcon' : 'EyeOffIcon'"
                                                    @click="confirmPasswordHidden = !confirmPasswordHidden"
                                                />
                                            </b-input-group-append>
                                        </b-input-group>
                                        <small
                                            class="text-danger"
                                            v-text="errors[0]"
                                        />
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12">
                                <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    variant="primary"
                                    @click="changePassword"
                                    block
                                >
                                    <span v-if="loading">
                                        <b-spinner small type="grow" />
                                        {{ $t('Message.loading') }}...
                                    </span>

                                    <span
                                        v-else
                                        v-text="$t(locale.btnPassword)"
                                    />
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </ValidationObserver>
        </div>
    </b-col>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import {accountSettingsMixins} from '@/mixins/accountSettingsMixins';
import useAppConfig from '@core/app-config/useAppConfig';

export default {
    mixins: [accountSettingsMixins],
    props: {
        locale: {
            type: Object,
        },
    },
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            config: useAppConfig(),
            newPasswordHidden: true, 
            confirmPasswordHidden: true,
            currentPasswordHidden: true,
            password: {
                current_password: '',
                new_password1: '',
                new_password2: '',
            },
            loading: '',
        };
    },

    computed: {
        resultMsg() {
            if (this.getLang == '') {
                return this.swedishLang ? 'message' : 'eng_message';
            } else return this.getLang == 'sv' ? 'message' : 'eng_message';
        },
        getLang() {
            return this.config.lang.value;
        },
    },
    methods: {
        resetForm() {
            this.password = {
                current_password: '',
                new_password1: '',
                new_password2: '',
            };
            this.$refs.form.reset();
        },
        changePassword() {
            this.$refs.form.validate().then(res => {
                if (res) {
                    this.loading = true;
                    this.$useJwt
                        .changePassword(this.password)
                        .then(res => {
                            this.popupMsg(
                                this.$t('Message.Success'),
                                res.data[this.resultMsg],
                                'CheckIcon',
                                'success'
                            );
                            this.resetForm();
                        })
                        .catch(err => {
                            if (err.response) {
                                const {data} = err.response;

                                this.popupMsg(
                                    this.$t('Message.Failed'),
                                    data[this.resultMsg],
                                    'AlertTriangleIcon',
                                    'danger'
                                );
                            }
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            });
        },
    },
};
</script>
