var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',{staticClass:"d-flex p-2 flex-column",staticStyle:{"gap":"1.5rem"},attrs:{"xl":"4"}},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"1rem"}},[_c('b-card-title',[_vm._v(" "+_vm._s(_vm.$t(_vm.locale.updateInformation))+" ")]),_c('ValidationObserver',{ref:"formUpdateInformation"},[_c('b-row',[_c('b-col',{attrs:{"xl":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t(_vm.locale.displayName)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t(_vm.locale.displayName)}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t(_vm.locale.displayName)},model:{value:(_vm.users.full_name),callback:function ($$v) {_vm.$set(_vm.users, "full_name", $$v)},expression:"users.full_name"}}),_c('small',{staticClass:"text-danger",domProps:{"textContent":_vm._s(errors[0])}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t(_vm.locale.fullName)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t(_vm.locale.fullName)}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t(_vm.locale.fullName)},on:{"input":function($event){return _vm.inputField('full_name', $event)}},model:{value:(_vm.users.full_name),callback:function ($$v) {_vm.$set(_vm.users, "full_name", $$v)},expression:"users.full_name"}}),_c('small',{staticClass:"text-danger",domProps:{"textContent":_vm._s(errors[0])}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required|email","name":_vm.$t(_vm.locale.email)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t(_vm.locale.email)}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t(_vm.locale.email)},on:{"input":function($event){return _vm.inputField('email', $event)}},model:{value:(_vm.users.email),callback:function ($$v) {_vm.$set(_vm.users, "email", $$v)},expression:"users.email"}}),_c('small',{staticClass:"text-danger",domProps:{"textContent":_vm._s(errors[0])}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"integer","name":_vm.$t(_vm.locale.phoneNumber)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t(_vm.locale.phoneNumber)}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t(_vm.locale.phoneNumber)},on:{"input":function($event){return _vm.inputField('phone', $event)}},model:{value:(_vm.users.phone),callback:function ($$v) {_vm.$set(_vm.users, "phone", $$v)},expression:"users.phone"}}),_c('small',{staticClass:"text-danger",domProps:{"textContent":_vm._s(errors[0])}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.disabledBtn,"block":""},on:{"click":_vm.updateProfile}},[(_vm.loading)?_c('span',[_c('b-spinner',{attrs:{"small":"","type":"grow"}}),_vm._v(" "+_vm._s(_vm.$t(_vm.locale.loading))+"... ")],1):_c('span',{domProps:{"textContent":_vm._s(_vm.$t(_vm.locale.btnProfile))}})])],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }