<template>
    <div>
        <b-card v-if="loadingState" class="d-flex">
            <Loading />
        </b-card>

        <div v-if="accountForm">
            <b-card>
                <b-card-text class="text-center">
                    <b-container>
                        <b-row
                            class="justify-content-center align-content-center"
                        >
                            <b-col
                                class="d-flex justify-content-sm-start"
                                xl="6"
                                cols="6"
                            >
                                <h2 class="mb-0">
                                    {{ $t(locale.title) }}
                                </h2>
                            </b-col>
                            <b-col
                                class="d-flex justify-content-sm-end"
                                xl="6"
                                cols="6"
                            >
                                <router-link
                                    class="d-flex text-secondaryColor justify-content-center"
                                    to="/dashboard"
                                >
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="22"
                                    />
                                    {{ $t(locale.anchor) }}
                                </router-link>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-card-text>

                <div class="mt-5">
                    <div class="container">
                        <b-row style="">
                            <UpdateInformation :locale="locale" />
                            <ChangePassword :locale="locale" />
                            <UpdateImage :locale="locale" />
                        </b-row>
                    </div>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import {getUserData} from '@/auth/utils';
import {accountSettingsMixins} from '@/mixins/accountSettingsMixins';
import UpdateInformation from '@/@core/components/accountsettings/UpdateInformation.vue';
import ChangePassword from '@/@core/components/accountsettings/ChangePassword.vue';
import UpdateImage from '@/@core/components/accountsettings/UpdateImage.vue';

export default {
    mixins: [accountSettingsMixins],

    components: {
        ChangePassword,
        UpdateImage,
        UpdateInformation,
    },
    data() {
        return {
            loading: false,
            loadingState: true,
            accountForm: false,
            userData: getUserData(),
            configurationParams: {},
            users: '',
            locale: {
                title: 'AcountSettings.Title',
                anchor: 'AcountSettings.Anchor',
                updateInformation: 'AcountSettings.UpdateInformation',
                changePassword: 'AcountSettings.changePassword',
                changePict: 'AcountSettings.updatePicture',
                btnProfile: 'AcountSettings.updateProfile',
                btnPassword: 'AcountSettings.updatePassword',
                displayName: 'AcountSettings.placeHolder.displayName',
                fullName: 'AcountSettings.placeHolder.fullName',
                email: 'AcountSettings.placeHolder.email',
                phoneNumber: 'AcountSettings.placeHolder.phoneNumber',
                currentPassword: 'AcountSettings.placeHolder.currentPassword',
                newPassword: 'AcountSettings.placeHolder.newPassword',
                confirmPassword: 'AcountSettings.placeHolder.confirmPassword',
                chooseNewPict: 'AcountSettings.newPict',
                currentPic: 'AcountSettings.currentPic',
                loading: 'Message.loading',
            },
        };
    },
    methods: {
        updatePict() {},
    },
};
</script>
