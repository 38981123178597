<template>
    <b-col xl="4" class="d-flex p-2 flex-column" style="gap: 1.5rem">
        <div class="d-flex flex-column" style="gap: 1rem">
            <b-card-title>
                {{ $t(locale.changePict) }}
            </b-card-title>

            <b-row>
                <b-col xl="12" class="d-flex flex-column" style="gap: 1rem">
                    <b-row>
                        <b-col cols="12">
                            <b-card
                                border-variant="secondary"
                                class="rounded-lg mt-2"
                            >
                                <b-card-title>
                                    {{ $t(locale.currentPic) }}
                                </b-card-title>
                                <ValidationObserver ref="form">
                                    <ValidationProvider name="Image">
                                        <b-card-text class="d-flex flex-column">
                                            <Loading v-if="loadingState" />

                                            <template v-if="showImage">
                                                <div
                                                    class="position-relative"
                                                    v-if="newImage"
                                                >
                                                    <img
                                                        width="100%"
                                                        :src="newImage"
                                                    />

                                                    <b-button
                                                        v-ripple.400
                                                        @click="newImage = ''"
                                                        size="sm"
                                                        variant="danger"
                                                        class="btn-icon position-absolute rounded-circle"
                                                        style="
                                                            top: -15px;
                                                            right: -15px;
                                                        "
                                                    >
                                                        x
                                                    </b-button>
                                                </div>

                                                <img
                                                    v-else
                                                    width="100%"
                                                    :src="users.profile_picture"
                                                />
                                            </template>

                                            <input
                                                hidden
                                                type="file"
                                                @change="inputImage"
                                                ref="imageInput"
                                            />

                                            <b-button
                                                class="mt-2"
                                                v-ripple.400
                                                @click="uploadImage"
                                                variant="flat-primary"
                                                v-text="
                                                    $t(locale.chooseNewPict)
                                                "
                                            />

                                            <span
                                                v-show="danger"
                                                class="text-danger"
                                                v-text="'File not valid'"
                                            />
                                        </b-card-text>
                                    </ValidationProvider>
                                </ValidationObserver>
                            </b-card>
                        </b-col>

                        <b-col cols="12">
                            <b-button
                                v-ripple.400
                                variant="primary"
                                block
                                :disabled="newImage == ''"
                                @click="saveImage"
                            >
                                <span v-if="loading">
                                    <b-spinner small type="grow" />
                                    {{ $t(locale.loading) }}...
                                </span>
                                <span v-else v-text="$t(locale.btnProfile)" />
                            </b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>
    </b-col>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import {accountSettingsMixins} from '@/mixins/accountSettingsMixins';
import useAppConfig from '@core/app-config/useAppConfig';

export default {
    mixins: [accountSettingsMixins],
    props: {
        locale: {
            type: Object,
        },
    },
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            config: useAppConfig(),
            configurationParams: {},
            formData: new FormData(),
            loading: '',
            newImage: '',
            loadingState: false,
            showImage: true,
            danger: false,
        };
    },
    computed: {
        profilePicture() {
            return this.newImage || this.users.profile_picture;
        },
        message() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_message' : 'eng_message';
            } else return this.getLang == 'sv' ? 'swe_message' : 'eng_message';
        },
        getLang() {
            return this.config.lang.value;
        },
    },

    methods: {
        inputImage() {
            const acceptedType = ['png', 'jpg', 'jpeg'];
            const fileType = this.$refs.imageInput.files[0].type.split('/')[1];
            const files = this.$refs.imageInput.files[0];

            if (acceptedType.includes(fileType)) {
                this.formData.append('profile_picture', files);
                var reader = new FileReader();

                reader.addEventListener('load', () => {
                    this.newImage = reader.result;
                });

                reader.readAsDataURL(files);
            } else this.danger = true;
        },
        uploadImage() {
            this.$refs.imageInput.click();
        },
        saveImage() {
            this.loading = true;

            this.loadingState = true;
            this.showImage = false;

            this.users.profile_picture = '';
            this.$useJwt
                .updateImage(this.formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then(res => {
                    const {data} = res;

                    this.popupMsg(
                        this.$t('Message.Success'),
                        data[this.message],
                        'CheckIcon',
                        'success'
                    );

                    this.getUsers();

                    this.loadingState = false;
                    this.showImage = true;
                })
                .finally(() => {
                    this.newImage = '';
                    this.loading = false;
                });
        },
    },
};
</script>
